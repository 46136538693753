import Cookies from 'js-cookie'

var Announcements = {
  COOKIE_KEY: 'dismissed-announcements',

  init: function() {
    var links = [].slice.call(document.querySelectorAll('.alert_dismiss'));

    links.forEach(function(link) {
      link.addEventListener('click', function(e) {
        e.preventDefault();
        Announcements.dismiss(link);
      });
    });
  },

  dismiss: function(link) {
    var id = link.getAttribute('data-id');

    if (id == undefined) {
      return;
    }
    // Set a cookie so that this banner doesn’t get displayed again.
    Announcements.setCookie(id);

    // Dismiss the banner.
    var banner = document.querySelector('#alert-' + id);
    banner.classList.add('alert--hidden');
  },

  setCookie: function(id) {
    var dismissed = [];
    var existingCookie = Cookies.get(Announcements.COOKIE_KEY);

    if (existingCookie != undefined) {
      dismissed = JSON.parse(existingCookie);
    }

    dismissed.push(id);

    Cookies.set(Announcements.COOKIE_KEY, JSON.stringify(dismissed), {
      expires: 14,
      samesite: 'Strict'
    });
  }
};

document.addEventListener('turbolinks:load', function() {
  Announcements.init();
});
